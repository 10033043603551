<template>
  <div v-if="isClient && loader">
    <v-progress-linear
      indeterminate
      color="primary"
      absolute
    ></v-progress-linear>
    <v-overlay
      :model-value="true"
      opbg="xs"
      class="align-center justify-center"
    >
      <div class="box" halign="center" extent="w-100 h-100">
        <div class="box" direction="column" gap="xs" tpd="md" bg="5xs" radius="3xs" bshadow="5xs">
          <!-- <p talign="center">
            <img
              :src="globals.getImage(`/base/logo-symbol-primary.webp`)"
              :alt="`${$t('site-name')}`"
              :width="48"
              :height="48"
              loader="lazy"
            />
          </p> -->
          <v-progress-linear
            color="primary"
            height="3"
            rounded
            :active="loader"
            :indeterminate="loader"
            mgt="3xs"
            mgb="3xs-neg"
          ></v-progress-linear>
          <span fsize="sm" color="xl">{{ $t('loader-wait') }}</span>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    isClient: {
      type: Boolean,
      default () {
        return false
      }
    },
    loader: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
}
</script>
